import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { useSetRecoilState } from 'recoil';
import { pageUrls } from '@constants';
import { sessionState } from '@globalState';

const useErrorHandlers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setPartnerConfig = useSetRecoilState(sessionState.atoms.selectedPartnerConfig);

  const onForbiddenError = useCallback(() => {
    setPartnerConfig(null);
    navigate(pageUrls.login, { replace: true });
  }, [navigate, setPartnerConfig]);

  const onConflictError = useCallback(
    (formHelpers: FormikHelpers<any>, fieldErrors: Record<string, string>) => {
      Object.keys(fieldErrors).forEach(field => {
        formHelpers.setFieldError(field, t(fieldErrors[field]));
      });
    },
    [t],
  );

  return {
    onForbiddenError,
    onConflictError,
  };
};

export default useErrorHandlers;
